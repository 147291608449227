import Fraction from "fraction.js";
import { PreprocessedStocksData } from "./ProductStocks";

const zero_values = {
  alf_batch_sum: 0,
  alf_batch_weight_kg: 0,
};

const unique_id_generator = {
  _id: 0,
  get id() {
    return `_${this._id++}`;
  },
  is_generated_id(id) {
    return id.startsWith("_");
  },
};

function makeSeriesCodeNonNull(product: PreprocessedStocksData): PreprocessedStocksData {
  return {
    ...product,
    batches: product.batches.map((x) => ({
      ...x,
      seriesCode: x.seriesCode == null ? unique_id_generator.id : x.seriesCode,
    })),
  };
}

function makeSeriesCodeFromNonNullToNull(product: PreprocessedStocksData): PreprocessedStocksData {
  return {
    ...product,
    batches: product.batches.map((x) => ({
      ...x,
      seriesCode: unique_id_generator.is_generated_id(x.seriesCode) ? null : x.seriesCode,
    })),
  };
}

function normalizeBatchCode(batchCode: string) {
  return batchCode.toLowerCase().trim();
}

/**
 * Merges warehouse and Twist preprocessed stock records data on batch
 * level.
 */
export function mergeWarehouseWithTwistStocks(warehouse: PreprocessedStocksData[], twist: PreprocessedStocksData[]) {
  return [...twist, ...warehouse]
    .map((pr) => pr.IDProduktu)
    .filter((v, i, a) => a.indexOf(v) === i)
    .map((id) => [
      twist.find((pr) => id === pr.IDProduktu) || ({ batches: [] } as PreprocessedStocksData),
      warehouse.find((pr) => id === pr.IDProduktu) || ({ batches: [] } as PreprocessedStocksData),
    ])
    .map(([tw, wa]) => [makeSeriesCodeNonNull(tw), wa])
    .map(([tw, wa]) => ({
      ...wa,
      ...makeSeriesCodeFromNonNullToNull(tw),
      batches: [...tw.batches.map((br) => br.seriesCode.trim()), ...wa.batches.map((br) => br.seriesCode.trim())]
        .filter((v, i, a) => {
          const normalizedValue = normalizeBatchCode(v);
          return a.findIndex(item => normalizeBatchCode(item) === normalizedValue) === i;
        })
        .map((bid) => ({
          twist:
            tw.batches.find((br) => normalizeBatchCode(br.seriesCode) === normalizeBatchCode(bid)) === undefined
              ? zero_values
              : tw.batches.find((br) => normalizeBatchCode(br.seriesCode) === normalizeBatchCode(bid)),
          warehouse:
            wa.batches.find((br) => normalizeBatchCode(br.seriesCode) === normalizeBatchCode(bid)) === undefined
              ? zero_values
              : wa.batches.find((br) => normalizeBatchCode(br.seriesCode) === normalizeBatchCode(bid)),
        }))
        .map((br) => ({
          ...br,
          sum_kg_ok:
            !!br.twist &&
            !!br.warehouse &&
            !!br.twist.alf_batch_weight_kg &&
            !!br.warehouse.alf_batch_weight_kg &&
            br.twist.alf_batch_weight_kg === br.warehouse.alf_batch_weight_kg,
          sum_mj_ok:
            !!br.twist &&
            !!br.warehouse &&
            !!br.twist.alf_batch_sum &&
            !!br.warehouse.alf_batch_sum &&
            br.twist.alf_batch_sum === br.warehouse.alf_batch_sum,
        })),
    }))
    .map((pr) => ({
      ...pr,
      super_sum_kg_twist: pr.batches.reduce(
        (acc, v) => acc.add(new Fraction((v.twist || {}).alf_batch_weight_kg) || new Fraction(0)),
        new Fraction(0)
      ),
      super_sum_kg_warehouse: fractionValueCheck(pr),
      super_sum_mj_twist: pr.batches.reduce(
        (acc, v) => acc.add(new Fraction((v.twist || {}).alf_batch_sum) || new Fraction(0)),
        new Fraction(0)
      ),
      super_sum_mj_warehouse: pr.batches.reduce(
        (acc, v) => acc.add(new Fraction((v.warehouse || {}).alf_batch_sum) || new Fraction(0)),
        new Fraction(0)
      ),
      all_batches_mj_ok: pr.batches.reduce((acc, v) => acc && v.sum_mj_ok, true),
      all_batches_kg_ok: pr.batches.reduce((acc, v) => acc && v.sum_kg_ok, true),
    }))
    .map((pr) => ({
      ...pr,
      super_sum_kg_ok:
        parseFloat(pr.super_sum_kg_twist.toString()) === parseFloat(pr.super_sum_kg_warehouse.toString()),
      super_sum_mj_ok:
        parseFloat(pr.super_sum_mj_twist.toString()) === parseFloat(pr.super_sum_mj_warehouse.toString()),
    }))
    .map((pr) => ({
      ...pr,
      stocks_check_status: (!pr.super_sum_kg_ok ? "danger" : !pr.all_batches_kg_ok ? "warning" : "") as
        | "danger"
        | "warning"
        | "", // "" means success - no class color applied
      problem: !pr.super_sum_kg_ok || !pr.all_batches_kg_ok, //for filtering problematic products
    }));
}

function fractionValueCheck(pr): Fraction | string {
  //this needs systematical approach
  try {
    return pr.batches.reduce(
      (acc, v) => acc.add(new Fraction((v.warehouse || {}).alf_batch_weight_kg) || new Fraction(0)),
      new Fraction(0)
    );
  } catch (e) {
    return "Chyba";
  }
}
