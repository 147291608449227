/**
 * Third step of stock notification process
 * @module orders/stock-notify-step3
 * @author Lucie Zdeňková <lucie.zdenek@trustica.cz>
 */
import React, { useState } from "react";
import Table from "react-bootstrap/Table";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { LinkContainer } from "react-router-bootstrap";
import Alert from "react-bootstrap/Alert";
import Image from "react-bootstrap/Image";
import { PurchaseOrderProcessedItem, Texts } from "./PurchaseOrder";
import { date_formatCZ, date_formatISO, date_isValidObject } from "../../lib/date-utils";
import { mesice } from "../../lists/months";
import { ErrorWrap } from "../../comp/errorwrap";
import { warehouses_names_ids_separe } from "../../lists/warehouses-defs";
import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";
import { StockNotificationFormValues } from "./StockNotifications";
import { DefaultError, useMutation, useQueryClient } from "@tanstack/react-query";
import { purchaseOrderCreateNotification } from "../../api/orders";

//seen all - OK

/**
 * Formats expiry information according to its type.
 */
function displayExpiration(expiry: string, expiryType: Dto.BatchExpiryType) {
  try {
    if (expiryType === "month-year") {
      const expiryDate = new Date(expiry);
      const year = expiryDate.getFullYear();
      const month = mesice[expiryDate.getMonth()][1];
      return month + " " + year;
    } else if (expiryType === "exact-date") {
      return date_formatCZ(expiry);
    } else if (date_isValidObject(expiry)) {
      return date_formatCZ(expiry);
    } else {
      return expiry;
    }
  } catch (error) {
    return "";
  }
}

interface StockNotificationStep3Props {
  orderId: number;
  orderCode: string;
  items: PurchaseOrderProcessedItem[];
  texts: any;
  hidden?: boolean;
}

export function StockNotificationStep3({
  orderId,
  orderCode,
  items,
  texts,
  hidden = false,
}: StockNotificationStep3Props) {
  const { t } = useTranslation();
  const { watch, getValues } = useFormContext<StockNotificationFormValues>();
  const eta = watch("eta");
  const pickedItems = watch("items");
  const [finalResult, setFinalResult] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>();

  const { mutate: createNotification, isPending: saving } = useMutation<
    Dto.SimpleResult,
    DefaultError,
    Dto.PostCreateOrderStockNotificationData
  >({
    mutationFn: () => {
      const data = getValues();
      const notify: Dto.PostCreateOrderStockNotificationData = {
        eta: date_formatISO(data.eta),
        items: data.items,
        unlockedItems: data.unlockedItems,
      };
      return purchaseOrderCreateNotification(orderId, notify);
    },
    onSuccess: (data) => {
      if (data.result === "ok") setFinalResult(true);
      else {
        setFinalResult(false);
        setErrorMessage(data["error"]);
      }
    },
    onError: (error, variables, context) => {
      setFinalResult(false);
      setErrorMessage(error.message);
    },
  });

  const selectedItems = items.filter((item) => !!pickedItems[item.itemId]);

  return (
    <div style={{ display: hidden ? "none" : undefined }}>
      <ErrorWrap>
        <Row>
          <Col>
            <h4>🕒 ETA: {eta && date_formatCZ(new Date(eta).toISOString())}</h4> ({t("ord-estimated_delivery_time")})
            <br />
            <br />
            <h4 className="mb-0">
              <Image src="/img/warehouse.svg" height="30" /> {t("warehouse")}{" "}
              {selectedItems[0] ? warehouses_names_ids_separe[selectedItems[0].item.warehouseBookCode] : ""}
            </h4>
          </Col>
          <Col xs="4">
            <Texts texts={texts} />
          </Col>
        </Row>

        <h5 className="mt-5">{t("ord-notifyied_items")}</h5>
        <Table size="sm" className="mb-3 border">
          <thead className="beGray">
            <tr>
              <th>{t("prod-id")}</th>
              <th>{t("name")}</th>
              <th className="text-center">{t("ord-remaining")}</th>
              <th className="text-end">{t("ord-notified_amount")}</th>
              <th className="text-center">{t("measure_unit")}</th>
              <th>{t("ord-batch")}</th>
              <th className="text-center">{t("ord-expiry")}</th>
            </tr>
          </thead>
          <tbody>
            {selectedItems.map(function (item, idx) {
              return (
                <tr key={idx}>
                  <td>{item.productId}</td>
                  <td className="align-middle">{item.item.productName}</td>
                  <td className="align-middle text-center">{item.item.remainingQuantity}</td>
                  <td className="text-end">
                    {pickedItems[item.itemId].batches.map(function (batches, idx) {
                      return <div key={idx}>{batches.amount}</div>;
                    })}
                  </td>
                  <td className="text-center align-middle">{item.item.unitOfMeasurementCode}</td>
                  <td>
                    {pickedItems[item.itemId].batches.map(function (batches, idx) {
                      return <div key={idx}>{batches.batch}</div>;
                    })}
                  </td>
                  <td className="text-muted align-middle text-center">
                    {pickedItems[item.itemId].batches.map(function (batches, idx) {
                      return <div key={idx}>{displayExpiration(batches.expiration, batches.expiryType)}</div>;
                    })}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
        <Row className="mt-4">
          <Col>
            <LinkContainer to={{ pathname: "/orders/purchase/" + orderCode + "/notification/2" }}>
              <Button disabled={saving || finalResult === true}>{t("ord-back_to_second_step")}</Button>
            </LinkContainer>
          </Col>
          <Col className="text-end"></Col>
        </Row>
        <br />
        <Row>
          <Col></Col>
          <Col xs="auto">
            {saving ? (
              <Button disabled className="p-2" variant="info">
                <h4 className="m-2">{t("ord-sending_to_wh")} ... &nbsp;&nbsp;</h4>
              </Button>
            ) : (
              <Button
                onClick={() => createNotification(undefined)}
                disabled={saving || finalResult === true}
                className="p-2"
                variant="info"
              >
                <h4 className="m-2 text-white">
                  {" "}
                  <Image src="/img/warehouse.svg" height="60" />
                  &nbsp; {t("ord-submit_sn_to_wh")} {selectedItems[0] ? selectedItems[0].item.warehouseBookCode : ""}{" "}
                </h4>
              </Button>
            )}
          </Col>
          <Col>
            {!finalResult ? (
              ""
            ) : (
              <FinalResult finalResult={finalResult} kodDokladu={orderCode} errorMessage={errorMessage} />
            )}
          </Col>
        </Row>
      </ErrorWrap>
    </div>
  );
}

interface FinalResultProps {
  finalResult: boolean;
  kodDokladu: string;
  errorMessage: string;
}

function FinalResult({ finalResult, kodDokladu, errorMessage }: FinalResultProps) {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const color = finalResult === true ? "success" : "danger";
  const message = finalResult === true ? t("successfully_sent") : t("error");
  return (
    <Alert variant={color} className="clearfix">
      <h5 className="mb-0">{message}</h5>
      <small>{errorMessage}</small>

      <br />
      <LinkContainer to={{ pathname: "/orders/purchase/" + kodDokladu }}>
        <Button
          onClick={() => queryClient.invalidateQueries({ queryKey: ["api/orders/purchase/", kodDokladu] })}
          size="sm"
          variant="primary"
          className="mb-2"
        >
          {t("ord-back_to_NO")}
        </Button>
      </LinkContainer>
    </Alert>
  );
}
