import { date_isAtMostNextWorkday } from "../../lib/date-utils";
import Fraction from "fraction.js";
import { PurchaseOrderProcessedItem } from "./PurchaseOrder";
import { TFunction } from "i18next";

//seen all - OK

/**
 * Checks whether given user input can be parsed as valid number, the
 * item contains valid product packaging and units specification and
 * validates the amount against this specification.
 */
export function checkSnAmount(user_input: string, item: PurchaseOrderProcessedItem, t: TFunction) {
  // No input at all
  if (user_input.length === 0) {
    return [null, null, null];
  }

  // Packaging weight in kg
  const pack_units = new Fraction(item.packageUnitOfMeasurementQuantity);
  const pack_unit_kg = new Fraction(item.unitOfMeasurementWeight);
  const pack_unit_kg_unit = item.unitOfMeasurementUnit; // to kontrolují checky na detailu a ty by mě nepustily dál, můžu klidně použít item.package_weight_wh
  if ((pack_unit_kg_unit || "").trim() !== "kg") {
    return [false, t("wh_unit_doesnt_have_kg_weight"), null];
  }
  if (pack_unit_kg.n === 0) {
    return [false, t("wh_unit_is_zero"), null];
  }
  if (pack_units.n === 0) {
    return [false, t("package_amount_is_zero"), null];
  }
  const pack_weight_kg = pack_unit_kg.mul(new Fraction(pack_units));

  // Sales - string check and conversion to number, remove redundant trailing decimal point
  const user_input_no_spaces = user_input.replaceAll(" ", "").replace(/[,.]$/, "");
  const user_input_regexp = /^[-]{0,1}[0-9]+([.,][0-9]+){0,1}$/;
  if (user_input_no_spaces.match(user_input_regexp) === null) {
    return [false, t("number_isnt_valid_number"), null];
  }
  const user_input_decimal_point = user_input_no_spaces.replace(",", ".");
  const user_fraction = new Fraction(user_input_decimal_point);

  // Sales amount converted to weight in kg
  const sales_unit_kg = new Fraction(item.item.storageUnitOfMeasurementWeight);
  const sales_unit_kg_unit = item.item.storageUnitOfMeasurementUnit;
  if ((sales_unit_kg_unit || "").trim() !== "kg") {
    return [false, t("sale_unit_is_missing_kg_weight"), null];
  }
  if (sales_unit_kg.n === 0) {
    return [false, t("sale_unit_has_zero_kg_weight"), null];
  }
  const user_fraction_in_kg = user_fraction.mul(sales_unit_kg);

  // Sales remaining
  const sales_remaining = item.item.remainingQuantity;
  const sales_remaining_kg = sales_unit_kg.mul(sales_remaining);

  // Perform the check
  return doCheckSnAmount(
    user_fraction_in_kg,
    pack_weight_kg,
    sales_unit_kg,
    sales_remaining_kg,
    user_input_decimal_point,
    t
  );
}

//console.log(check_sn_amount("bllaa", {pjo_MnozstviSklMjVObalu: " "}));

/**
 * Checks whether given amount in kg is an integer multiple of
 * packaging size in kg, it is positive and less than remaining amount.
 */
export function doCheckSnAmount(
  amount_kg: Fraction,
  pack_kg: Fraction,
  sales_unit_kg: Fraction,
  sales_remaining_kg: Fraction,
  clean_result: string,
  t: TFunction
) {
  if (sales_remaining_kg < pack_kg) {
    return [false, "remaining_is_less_than_one_package", null];
  }
  if (amount_kg.s < 0 || amount_kg.n === 0) {
    return [false, "positive_number_required", null];
  }

  // How many packagings are there
  const pack_count = amount_kg.div(pack_kg);
  if (pack_count.d !== 1) {
    // If non-integer multiply, suggest surrounding integer multiplies
    const pack_count_lower = pack_count.floor();
    const pack_count_higher = pack_count.ceil();
    const pack_count_min = new Fraction(1); // Checked above
    const pack_count_max = sales_remaining_kg.div(pack_kg).floor();
    const pack_kg_min = pack_count_min.mul(pack_kg);
    const pack_kg_max = pack_count_max.mul(pack_kg);
    const pack_sales_min = pack_kg_min.div(sales_unit_kg);
    const pack_sales_max = pack_kg_max.div(sales_unit_kg);
    const pack_kg_lower = pack_count_lower.mul(pack_kg);
    const pack_kg_higher = pack_count_higher.mul(pack_kg);
    const pack_sales_lower = pack_kg_lower.div(sales_unit_kg);
    const pack_sales_higher = pack_kg_higher.div(sales_unit_kg);
    const pack_sales_lower_clamped = fractionClamp(pack_sales_lower, pack_sales_min, pack_sales_max);
    const pack_sales_higher_clamped = fractionClamp(pack_sales_higher, pack_sales_min, pack_sales_max);

    // Limit to non-zero suggestions smaller or equal to remaining amount
    const suggestions = [pack_sales_lower_clamped, pack_sales_higher_clamped]
      .filter((amount) => amount.n > 0 && amount.compare(sales_remaining_kg) <= 0)
      .map((n) => n.toString())
      .filter((v, i, a) => a.indexOf(v) === i);

    return [
      false,
      t("you_have_to_fill_whole_multiple") +
        " " + //t musím udělat už tu, jinak to překládá celý
        suggestions.join(" " + t("or") + " ") +
        ".",
      null,
    ];
  }

  // More than remaining?
  if (amount_kg.compare(sales_remaining_kg) > 0) {
    const pack_count_max = sales_remaining_kg.div(pack_kg).floor();
    const pack_kg_max = pack_count_max.mul(pack_kg);
    const pack_sales_max = pack_kg_max.div(sales_unit_kg);
    return [false, t("cannot_notify_more_than_remaining", { amount: pack_sales_max }), null];
  }

  // Return cleaned user input upon success
  return [true, null, clean_result];
}

/**
 * Checks whether the batch is non-empty string for pwc_id 1 and 2.
 */
export function checkSnBatch(user_input: string, item: PurchaseOrderProcessedItem, eta: string, batch_amount: string) {
  //console.log(user_input);
  if (user_input === null) {
    return [null, null, null];
  }
  if (!Object.keys(item).includes("warehouseConfiguration")) {
    return [false, "missing_bbd_batch_settings", null];
  }
  const pwc_id = item.warehouseConfiguration.type;
  if (pwc_id === 3) {
    return [true, null, null];
  }
  if (user_input.trim().length === 0) {
    /*
        if (is_sn_batch_check_special(item, eta, batch_amount)) {
            return [null, null, user_input];
        }
        */
    return [false, "batch_mandatory", null];
  }
  return [true, null, user_input];
}

/**
 * Checks whether this item is special case - at least 5t or ETA is
 * next working day. This function works correctly only if check_sn_amount()
 * succeeded at least with specification tests.
 */
export function isSnBatchCheckSpecial(item: PurchaseOrderProcessedItem, eta: string, batch_amount: string) {
  if (batch_amount !== null) {
    // >= 5t, validity of product specification was checked in check_sn_amount
    const amount_fraction = new Fraction(batch_amount);
    const sales_remaining = item.item.remainingQuantity;
    if (amount_fraction.compare(sales_remaining) <= 0) {
      // The amount is less than remaining
      const sales_unit_kg = new Fraction(item.item.storageUnitOfMeasurementWeight);
      const amount_fraction_in_kg = amount_fraction.mul(sales_unit_kg);
      if (amount_fraction_in_kg.compare(5000) >= 0) {
        // More than 5t and actually possible - special case
        return true;
      }
    }
  }
  // Check tomorrow
  if (date_isAtMostNextWorkday(eta)) {
    return true;
  }
  // No special case
  return false;
}

/**
 * Clamps given value in the specified interval. Works internally with
 * Fraction representation only.
 */
export function fractionClamp(value: number | Fraction, minimum: number | Fraction, maximum: number | Fraction) {
  const f_value = new Fraction(value);
  const f_min = new Fraction(minimum);
  const f_max = new Fraction(maximum);
  if (f_value.compare(f_min) < 0) {
    return f_min;
  }
  if (f_value.compare(f_max) > 0) {
    return f_max;
  }
  return f_value;
}
